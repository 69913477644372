import Storage from "./storage";
import { history } from "../shared/configure-store"

class AuthHelper {

    static setLogin = (resp) => {

        Storage.set('access_token', resp.token);

        Storage.set('expired_at', resp.expires_in);

    };

    static setProfile = (resp) => {

        Storage.set('profile-client', resp);

    };

    static getProfile() {

        return Storage.get('profile-client');

    };

    static logOut() {

        Storage.clear();

        history.push('/login');

    };

    static isLoggedIn() {

        return !!Storage.get('profile-client');

    };

}

export default AuthHelper;
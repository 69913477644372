import React, {Component} from 'react';
import NotFound from "../not-found";
import {Switch} from 'react-router-dom';

import AuthHelper from "../../../libraries/auth-helper";
import Logo from '../../../images/logo.png';
import moment from "moment/moment";
import Api from "../../../libraries/api";
import user from "../../../images/user.png";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import {PrivateRoute} from '../../../libraries/route-handling'
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import {history} from "../../../shared/configure-store";

import Home from "../home";
import Detail from "../home/detail";

const styleDashboard = {
    zIndex: '5',
    position: 'relative',
};

class Dashboard extends Component {

    constructor(props) {

        super(props);
        this.state = {
            photo: user,
            name: '',
            city: '',

        };
    }

    componentDidMount() {

        if(AuthHelper.isLoggedIn()){
            this.props.setProfile(AuthHelper.getProfile());
            let data = AuthHelper.getProfile();

            if(data) {
                this.setState({
                    loading: false,
                    photo: user,
                    name: data.name,
                    city: data.city,
                })
            }

        }else{
            history.push('/login');
        }
    }

    handleLogout = () => {

        AuthHelper.logOut();

    };

    render() {
        const date = Date.now();
        return (

            <div>
                <header className="header-nav ">
                    <nav className="navbar justify-content-start">
                        <div className="navbar-brand m-0 p-0">
                            <img src={Logo} alt="abadi" className="logo" />
                        </div>
                        {/*<div className="date d-none d-xl-block">*/}
                        {/*    <p className="mb-0 mr-3">{moment(date).format('DD MMM YYYY, dddd')}</p>*/}
                        {/*</div>*/}
                        <div className="nav-item profile-picture">
                            <img src={this.state.photo} alt="user" className="user-photo mr-2"/>
                            <div className="content">
                                <h3>{this.state.name}</h3>
                                <span>{this.state.city}</span>
                            </div>
                        </div>

                        <button onClick={this.handleLogout} className='btn btn-blue'>
                            Logout
                        </button>
                    </nav>
                </header>
                <main className={"dashboard " + (this.props.toggle_sidebar_state ? "show" : "")  }>

                    <div className="container-fluid">

                        <div className="row">

                            <div className="col-xl dashbboard-container" style={styleDashboard}>

                                <Switch>

                                    <PrivateRoute exact path="/" component={Home}/>
                                    <PrivateRoute path="/detail/:id" component={Detail}/>


                                    <PrivateRoute component={NotFound}/>
                                </Switch>

                            </div>

                        </div>

                    </div>

                </main>

            </div>

        )

    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

        toggle_sidebar_state: state.toggle_sidebar_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);


import React,{Component} from 'react';
import img from '../../../images/home.png';
import {Link} from 'react-router-dom';

import Api from "../../../libraries/api";
import moment from "moment/moment";
import Images from '../../../images/home.png';
import CircularProgress from '@material-ui/core/CircularProgress';

import Logo from "../../../images/logo.png";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import AuthHelper from "../../../libraries/auth-helper";
import user from "../../../images/user.png";
import NumberFormat from "react-number-format";
class Home extends Component {
    constructor(props) {

        super(props);
        this.state = {
            loading: false,

            data1: 0,
            id: '',
            name: '',
            type: '',
            totalCase: '',
            totalOnprogress: '',
            totalFollowup: '',
            pendingIdr: '',
            pendingUsd: '',

            case: [],
            pending: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,
        }
    }

    componentDidMount() {
        document.title = 'Abadi - Client Portal';

        this.setState({
            loading: true,
        });

        let data = AuthHelper.getProfile();

        if(data) {
            this.setState({
                loading: false,
                photo: user,
                name: data.name,
                city: data.city,
                type: data.type,
                id: data.id,
            }, () => {
                this.__fetchData(false);
            });
        }
    }

    __fetchData = update => {
        this.setState({
            loading: true,
        });
        let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;

        let sort = this.state.orderBy ? '&orderBy=' + this.state.orderBy + '&sortedBy=' + this.state.sortedBy : '';
        let search = this.state.searchBy ? '&search=' + this.state.searchBy : '';

        let route = '/reports/'+this.state.id+'/os-clients';
        Api.get(route).then(resp => {
            let data = resp.data;
            this.setState({
                case: data.case,
                pending: data.pending,
                totalCase: resp.data.total_all.total_case,
                totalOnprogress: resp.data.total_all.total_onprogress,
                totalFollowup: resp.data.total_all.total_followup,
                pendingIdr: resp.data.total_all.pending_idr,
                pendingUsd: resp.data.total_all.pending_usd,
                loading: false,
            });
        }).catch(err => {
            console.log(err);
        });
    };

    handleChangePage = () => {
        this.setState({
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    filterStatus = (name) => {
        let text = '';

        switch(name) {
            case 'Follow Up':
                text = 'yellow';
                break;
            case 'On Progress':
                text = 'blue';
                break;
            case 'on progress':
                text = 'blue';
                break;
            case 'Finish':
                text = 'purple';
                break;
            case 'Completed':
                text = 'green';
                break;
            case 'Cancel':
                text = 'red';
                break;
            case 'Pending':
                text = 'yellow';
                break;
            case 'Partial Payment':
                text = 'green';
                break;
            case 'Complete Payment':
                text = 'blue';
                break;
            default:
                text = 'yellow';
        }

        return text;
    };

    render() {

        return (

            <div className="row main-content">
                <div className="col-12">
                    <h2 className="page-title text-uppercase">OUSTANDING LIST {this.state.name} </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                        </ol>
                    </nav>

                    <div className='card-red'>
                        <div className='row'>
                            <div className='col-8'>
                                <h3>Welcome, <br/><br/>
                                    Please click the comment button next to the case you want to ask / need further information.</h3>
                            </div>
                            <div className='col-4'>
                                <img src={Images} alt="abadi" className="logo" />
                            </div>
                        </div>

                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md'>
                                    <div className="card-info green">
                                        <label className="label-grey">Total <br />Case</label>
                                        <p className="number">{this.state.totalCase && this.state.totalCase}</p>
                                    </div>
                                </div>
                                <div className='col-md'>
                                    <div className="card-info green">
                                        <label className="label-grey">On Progress <br />Case</label>
                                        <p className="number">{this.state.totalOnprogress && this.state.totalOnprogress}</p>
                                    </div>
                                </div>
                                <div className='col-md'>
                                    <div className="card-info green">
                                        <label className="label-grey">Follow Up <br />Case</label>
                                        <p className="number">{this.state.totalFollowup && this.state.totalFollowup}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md'>
                                    <div className="card-info yellow">
                                        <label className="label-grey">Total Not Paid <br /> Invoice IDR</label>
                                        <p className="number">
                                            <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.pendingIdr && this.state.pendingIdr} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md'>
                                    <div className="card-info yellow">
                                        <label className="label-grey">Total Not Paid <br /> Invoice USD</label>
                                        <p className="number">
                                            <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.pendingUsd && this.state.pendingUsd} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-wrapper mt-3">
                        <h2 className='section-title'>ON GOING CASE(S)</h2>
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>No</TableCell>
                                    <TableCell className='text-center'>No Case</TableCell>
                                    <TableCell className='text-center'>Vessel Name</TableCell>
                                    <TableCell className='text-center'>Casualty</TableCell>
                                    <TableCell className='text-center'>Appointment Date</TableCell>
                                    <TableCell className='text-center'>Type</TableCell>
                                    <TableCell className='text-center'>Status</TableCell>
                                    <TableCell className='text-center'>Survey Status</TableCell>
                                    {this.state.type === 'Underwriter' &&
                                    <TableCell className='text-center'>Last Comment</TableCell>
                                    }
                                    {this.state.type === 'Underwriter' &&
                                    <TableCell className='text-center'>Comment History</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={10} align="center" className="py-5">
                                            <CircularProgress color="primary"  />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.case.length === 0 ? (
                                        <TableRow style={{ height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={10} style={{ textAlign: "center" }}>No OS client</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.case.map((row, key) => (
                                            <TableRow
                                                key={row.id}
                                                selected={row.selected}>
                                                <TableCell>
                                                    {key + 1}
                                                </TableCell>
                                                <TableCell>
                                                    <span className='text-uppercase'>{row.case_number === null ? 'NA' : row.case_number}</span>
                                                </TableCell>
                                                <TableCell>{row.vessel_name === null ? 'NA' : row.vessel_name}</TableCell>
                                                <TableCell>{row.casualty === null ? 'NA' : row.casualty}</TableCell>
                                                <TableCell>{row.instruction_date && moment(row.instruction_date,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                                <TableCell>{row.project_type}[{row.detail_type}]</TableCell>
                                                <TableCell>
                                                    <span
                                                        className={'label-status label-' + this.filterStatus(row.project_status !== null && row.project_status)}>{row.project_status === null ? '' : row.project_status}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span
                                                        className={'label-status label-' + this.filterStatus(row.project_status !== null && row.project_status)}>{row.survey_status === null ? '' : row.survey_status}</span>
                                                </TableCell>

                                                {this.state.type === 'Underwriter' &&
                                                <TableCell>{row.client_comment === null ? 'NA' : row.client_comment}</TableCell>
                                                }
                                                {this.state.type === 'Underwriter' &&
                                                <TableCell>
                                                    <a href={'/detail/'+row.project_id} target='_blank' className="btn btn-blue">
                                                        COMMENT
                                                    </a>
                                                </TableCell>
                                                }
                                            </TableRow>
                                        ))
                                    )
                                )}

                            </TableBody>
                        </Table>

                        <hr />
                        <h2 className='section-title'>OUTSTANDING INVOICE (S)</h2>
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>No</TableCell>
                                    <TableCell className='text-center'>No Case</TableCell>
                                    <TableCell className='text-center'>Vessel Name</TableCell>
                                    <TableCell className='text-center'>Casualty</TableCell>
                                    <TableCell className='text-center'>Type</TableCell>
                                    <TableCell className='text-center'>Invoice No</TableCell>
                                    <TableCell className='text-center'>Date Issued Invoice</TableCell>
                                    <TableCell className='text-center'>Shared%</TableCell>
                                    <TableCell className='text-center'>Amount</TableCell>
                                    <TableCell className='text-center'>Files</TableCell>
                                    <TableCell className='text-center'>Status</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={11} align="center" className="py-5">
                                            <CircularProgress color="primary"  />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.pending.length === 0 ? (
                                        <TableRow style={{ height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={11} style={{ textAlign: "center" }}>No OS client</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.pending.map((row, key) => (
                                            <TableRow
                                                key={row.id}
                                                selected={row.selected}>
                                                <TableCell>
                                                    {key + 1}
                                                </TableCell>
                                                <TableCell>
                                                    <span className='text-uppercase'>{row.case_number === null ? 'NA' : row.case_number}</span>
                                                </TableCell>
                                                <TableCell>{row.vessel_name === null ? 'NA' : row.vessel_name}</TableCell>
                                                <TableCell>{row.casualty === null ? 'NA' : row.casualty}</TableCell>
                                                <TableCell>{row.project_type}[{row.detail_type}]</TableCell>
                                                <TableCell>{row.invoice_number === null ? 'NA' : row.invoice_number}</TableCell>
                                                <TableCell>{row.date_issued && moment(row.date_issued,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                                <TableCell>{row.share_percentage === null ? 'NA' : row.share_percentage}</TableCell>
                                                <TableCell>
                                                    {row.currency === 'USD' && '$ '}
                                                    {row.currency === 'IDR' && 'Rp '}
                                                    <NumberFormat thousandSeparator={true} value={row.total_amount} displayType={'text'} renderText={value => value}/></TableCell>

                                                <TableCell>
                                                    <span>File Invoice: </span>
                                                    {row.file_invoice &&
                                                    <a href={process.env.REACT_APP_API_STORAGE_PATH + row.file_invoice} target='_blank'>
                                                        <i className="fas fa-file"> </i> {row.file_invoice}
                                                    </a>
                                                    }

                                                    <br />
                                                    <span>File Report: </span>
                                                    {row.file_report &&
                                                    <a href={process.env.REACT_APP_API_STORAGE_PATH + row.file_report}
                                                       target='_blank'>
                                                        <i className="fas fa-file"> </i> {row.file_report}
                                                    </a>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <span className={'label-status label-'+this.filterStatus(row.status_invoice !== null && row.status_invoice)}>{row.status_invoice === null ? '' : row.status_invoice}</span>

                                                </TableCell>

                                            </TableRow>
                                        ))
                                    )
                                )}

                            </TableBody>
                        </Table>
                    </div>


                </div>

            </div>

        )

    }
}

export default Home;
import React,{Component} from 'react';
import img from '../../../images/home.png';
import {Link} from 'react-router-dom';

import Api from "../../../libraries/api";
import Skeleton from '@material-ui/lab/Skeleton';
import SendIcon from '@material-ui/icons/Send';

import moment from "moment/moment";

import ErrorHandling from '../../../libraries/error-handling';
import {toast, ToastContainer} from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import AuthHelper from "../../../libraries/auth-helper";
import user from "../../../images/user.png";

const validatorCommentClient = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class Home extends Component {
    constructor(props) {

        super(props);
        this.state = {
            errors: {},
            loading: false,
            loadingCommentListClient: false,
            comment_list_client: [],
            client_comment: '',
            case_number: '',
            project_number: '',
            ext_project_number: '',
            case_type: '',
            type: '',
            detail_type: '',
            detail_type_id: '',
            vessel_name: '',
            vessel_type: '',
            vessel_grt: '',
            vessel_yob: '',
        }
    }

    componentDidMount() {
        document.title = 'Abadi - Client Portal';

        this.setState({
            loading: true,
        });

        let data = AuthHelper.getProfile();

        if(data) {
            this.setState({
                loading: false,
                photo: user,
                name: data.name,
                city: data.city,
                type: data.type,
                id: data.id,
            }, () => {
                this.__fetchComment(false);
                this.__fetchData(false);
            });
        }
    }

    __fetchData = update => {
        this.setState({
            loading: true,
        });
        const id = this.props.match.params.id;

        Api.get('/projects/'+id+'/client').then(resp => {

            if (resp.data) {
                let data = resp.data;

                this.setState({
                    loading: false,
                    case_number: data.caseNumber,
                    project_number: data.projectNumber,
                    ext_project_number: data.extProjectNumber,
                    case_type: data.caseType,
                    type: data.type ? data.type.name : '',
                    detail_type: data.detailType ? data.detailType.name :'',
                    detail_type_id: data.detailType ? data.detailType.id :'',
                    vessel_name: data.vesselName,
                    vessel_type: data.vesselType,
                    vessel_grt: data.vesselGrt,
                    vessel_yob: data.vesselYob,
                })

            }

        }).catch(err => {
            console.log(err);
            this.setState({
                loading: false,
            });
        });
    };
    __fetchComment = () => {
        const id = this.props.match.params.id;

        Api.get('/client-comments?search=projects_id:'+id+'&limit=0').then(resp => {

            if (resp.data) {

                this.setState({
                    comment_list_client: resp.data,
                    loadingCommentListClient: false
                });

                if(this.props.profile_state) {
                    this.setState({
                        user_id_current: this.props.profile_state.id,
                    })
                }
            }

        }).catch(err => {
            console.log(err);
            this.setState({
                loadingCommentListClient: false,
            });
        });

    };

    handleCommentClient = (e) => {
        e.preventDefault();

        if (!validatorCommentClient.allValid()) {

            this.setState({
                    errors: validatorCommentClient.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingComment: true,
            }
        );

        let param = {
            projects_id: this.props.match.params.id,
            client_id: this.state.id,
            type: 'comment',
            comment: this.state.client_comment,
        };

        Api.post('/client-comments', param, false).then(resp => {

            if (resp.data) {
                this.__fetchComment();
                this.setState({
                    loadingComment: false
                });
                this.showMessage(true, 'Comment posted');

            }

        }).catch(err => {
            this.setState({
                errors: err.errors,
                loadingComment: false
            });

            this.showMessage(false, 'Invalid format data');
        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };
        render() {

        return (

            <div className="row main-content">
                <div className="col-12">
                    <h2 className="page-title">DETAIL </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Detail</li>
                        </ol>
                    </nav>

                    <div className='row'>
                        <div className='col-md-6'>
                            {
                                this.state.loading ?
                                    <div className='table-wrapper detail mb-3'>
                                        <h2 className='title-wrap'>Information</h2>
                                        <Skeleton variant='rect' className='mb-3' height={118}/>
                                        <Skeleton variant='rect' className='mb-3' height={118}/>
                                        <Skeleton variant='rect' className='mb-3' height={118}/>
                                        <Skeleton variant='rect' className='mb-3' height={118}/>
                                        <Skeleton variant='rect' className='mb-3' height={40}/>
                                        <Skeleton variant='rect' className='mb-3' height={40}/>
                                    </div> :
                                    <div className='table-wrapper detail mb-3'>
                                        <h2 className='title-wrap'>Information</h2>
                                        <div className='card-detail'>
                                            <h3>Internal Data</h3>
                                            <p>Case number: <span>{this.state.case_number}</span></p>
                                            <p>Project number: <span>{this.state.project_number}</span></p>
                                            <p>Ext project number: <span>{this.state.ext_project_number}</span></p>
                                            <p>Case Type: <span>{this.state.case_type}</span></p>
                                            <p>Project type: <span>{this.state.type}</span></p>
                                            <p>Project detail type: <span>{this.state.detail_type}</span></p>
                                        </div>
                                        <div className='card-detail'>
                                            <h3>Vessel Particular</h3>
                                            <p>Vessel name: <span>{this.state.vessel_name}</span></p>
                                            <p>Vessel type: <span>{this.state.vessel_type}</span></p>
                                            <p>Vessel GRT: <span>{this.state.vessel_grt}</span></p>
                                            <p>Vessel YOB: <span>{this.state.vessel_yob}</span></p>
                                        </div>
                                    </div>
                            }
                        </div>


                        <div className='col-md-6 comment-wrap'>
                            <h3>Comment Client</h3>

                            <div className='comment-container'>
                                { this.state.loadingCommentListClient ?
                                    <div className='row mb-2'>
                                        <div className='col img-avatar'>
                                            <Skeleton variant='circle' width={60} height={60} />
                                        </div>
                                        <div className='col'>
                                            <Skeleton variant='text' />
                                            <Skeleton variant='text' />
                                        </div>
                                    </div> :
                                    this.state.comment_list_client.length === 0 ?
                                        <div className='col img-avatar mb-2'>
                                            <p>no comment</p>
                                        </div>
                                        :
                                        this.state.comment_list_client.map(row => (
                                            <div className='row mb-2' key={row.id}>
                                                <div className='col img-avatar'>
                                                    <img src={row.user ? row.user.avatar !== null ? process.env.REACT_APP_API_STORAGE_PATH+row.user.avatar : user : user} alt='avatar' className='img-fluid' />
                                                </div>
                                                <div className='col'>
                                                    <h4>{row.user ? row.user.name : ''} {row.client ? row.client.name : ''}</h4>
                                                    <p>{row.comment}</p>
                                                    {row.image &&
                                                    <img
                                                        src={process.env.REACT_APP_API_STORAGE_PATH + row.image}
                                                        alt='avatar' className='img-fluid'/>
                                                    }
                                                    <time>{moment(row.created_at).format('DD/MM/YYYY HH:mm:ss')}</time>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>

                            <form>
                                <input
                                    name='client_comment'
                                    onChange={(e) => this.handleChange(e, 'client_comment')}
                                    value={this.state.client_comment}
                                    placeholder='Enter your message'
                                />
                                <button onClick={this.handleCommentClient} >
                                    { this.state.loadingComment ? <i className='fa fa-spinner fa-spin'> </i>
                                        : <SendIcon />
                                    }
                                </button>
                            </form>

                            {validatorCommentClient.message('client_comment', this.state.client_comment, 'required')}
                            <div className='text-danger mb-2'>{this.state.errors.client_comment}</div>

                        </div>

                    </div>


                </div>

            </div>

        )

    }
}

export default Home;